import { Table, Input, Row, Col, Typography, Button } from "antd";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import AddOverlay from "./AddCustomer";
import EditOverlay from "./EditCustomer";
import edit from "../../../assets/images/edit.png";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
require("./Customer.scss");
import type { ColumnsType } from "antd/es/table";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  convertNumber,
  checkNumber,
  formatCurrencyValue,
  convertCurrency
} from "../../../utils/util";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { customer } from "../../../utils/enums";

type Customer = {
  name?: string[]; 
}

const Customers = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [customers, setCustomers] = useState([]);
  const { Text } = Typography;
  const [getTotalStuSold, setTotalStuSold] = useState(0);
  // const [getTotalDirectExpense, setTotalDirectEexpense] = useState(0);
  const [getTotalRev, setTotalRev] = useState(0);
  // const [getTotalAvgStu, setTotalAvgStu] = useState(0);
  const [getTotalExpStu, setTotalExpStu] = useState(0);
  const [getTotalEar, setTotalEar] = useState(0);
  // const [getTotalAvgEar, setTotalAvgEar] = useState(0);
  const [getTotalFutu, setTotalFutu] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
 const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
 const [customersData, setCustomersData]:any = useState([]);
 
 
 const [appliedFilters, setAppliedFilters] = useState<Customer>({});

  let token = getToken();
 

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getData();
    }
  }, [currencySymb?.currency_code]);

  useEffect(() => {
    let totalStuSold = 0;
    let totalRev = 0;
    let totalDirectExpense = 0;
    let totalAvgStu = 0;
    let totalExpStu = 0;
    let totalEar = 0;
    let totalAvgEar = 0;
    let totalFutu = 0;
    if (nameSearch.length !== 0) {
      const results: any = data.filter((item: any) =>
        item.Customer_name.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
      setFilterTotalCount(results.length);
      results.map((res) => {
        res.gained_base = res.gained_base ? res.gained_base : 0;
        res.dropped_base = res.dropped_base ? res.dropped_base : 0;
        totalStuSold = totalStuSold + res.stuSold;
        totalRev = totalRev + res.value;
        totalDirectExpense = totalDirectExpense + res.direct_expense;
        totalAvgStu = totalAvgStu + returnAvg(res.stuSold, res.value);
        totalExpStu = totalExpStu + res.stuExpended;
        totalEar = totalEar + res.earnedRev;
        totalAvgEar = totalAvgEar + returnAvg(res.stuExpended, res.earnedRev);
        totalFutu =
          totalFutu +
          futureSub(
            res.value,
            res.earnedRev,
            res.gained_base,
            res.dropped_base
          );
      });
      setTotalStuSold(totalStuSold);
      setTotalRev(totalRev);
      // setTotalDirectEexpense(totalDirectExpense);
      // setTotalAvgStu(totalAvgStu);
      setTotalExpStu(totalExpStu);
      setTotalEar(totalEar);
      // setTotalAvgEar(totalAvgEar);
      setTotalFutu(totalFutu);
    } else {
      setFilterData(data);
      setFilterTotalCount(data.length);
      data.forEach((res:any) => {
        
        res.gained_base = res.gained_base ? res.gained_base : 0;
        res.dropped_base = res.dropped_base ? res.dropped_base : 0;
        totalStuSold = totalStuSold + res.stuSold;
        totalRev = totalRev + res.value;
        totalDirectExpense = totalDirectExpense + res.direct_expense;
        totalAvgStu = totalAvgStu + returnAvg(res.stuSold, res.value);
        totalExpStu = totalExpStu + res.stuExpended;
        totalEar = totalEar + res.earnedRev;
        totalAvgEar = totalAvgEar + returnAvg(res.stuExpended, res.earnedRev);
        totalFutu =
          totalFutu +
          futureSub(
            res.value,
            res.earnedRev,
            res.gained_base,
            res.dropped_base
          );
      });
      setTotalStuSold(totalStuSold);
      setTotalRev(totalRev);
      // setTotalDirectEexpense(totalDirectExpense);
      // setTotalAvgStu(totalAvgStu);
      setTotalExpStu(totalExpStu);
      setTotalEar(totalEar);
      // setTotalAvgEar(totalAvgEar);
      setTotalFutu(totalFutu);
    }
  }, [nameSearch]);

  useEffect(() => {
    const tableData:any = customersData.map(
      (row: {
        name: any;
        id: any;
        stus_sold: any;
        $Value: any;
        projects: any;
        direct_expense: any;
        revenue_value: any;
        stus_expended: any;
        gained_base: any;
        dropped_base: any;
      }) => ({
        Customer_name: row.name,
        Stu_Sold: convertNumber(row.stus_sold, 2),
        stuSold: row.stus_sold,
        direct_expense: row.direct_expense,
        Value: convertCurrency(row?.$Value,currencyRate),
        value: row.$Value,
        avgSale: returnAvg(row.stus_sold, row.$Value),
        Average_Sale:convertCurrency(returnAvg(row?.stus_sold, row?.$Value - row?.direct_expense),currencyRate),
        CustomerId: row.id,
        Projects: row.projects,
        stus_expended: convertNumber(row.stus_expended, 2),
        stuExpended: row.stus_expended,
        Earned_rev:convertCurrency( row?.revenue_value,currencyRate),
        earnedRev: row.revenue_value,
        avgEarned_filter: returnAvg(row.stus_expended, row.revenue_value),
        Average_Earned:convertCurrency( returnAvg(
          row?.stus_expended,
          row?.revenue_value - row?.direct_expense
        ),currencyRate),
        gainedBase: row.gained_base,
        droppedBase: row.dropped_base,
        futuEarned_filter: futureSub(
          row.$Value,
          row.revenue_value,
          row.gained_base,
          row.dropped_base
        ),
        Future_earn:convertCurrency(  futureSub(
          row?.$Value,
          row?.revenue_value,
          row?.gained_base,
          row?.dropped_base
        ),currencyRate)
      })
    );
    setData(
      customersData.map(
        (row: {
          name: any;
          id: any;
          stus_sold: any;
          $Value: any;
          projects: any;
          revenue_value: any;
          stus_expended: any;
          gained_base: any;
          dropped_base: any;
          droppedBase: any;
          gainedBase: any;
          Gained_base: any;
          Dropped_base: any;
          stuSold: any;
          direct_expense: any;
        }) => ({
          Customer_name: row.name,
          direct_expense: convertCurrency(
            
            row.direct_expense,currencyRate
          ),
          Stu_Sold: convertNumber(row.stus_sold, 2),
          stuSold: row.stus_sold,
          Value:convertCurrency(row.$Value,currencyRate),
          value_in_base: formatCurrencyValue(
            currencySymb?.currency_code,
            row.$Value
          ),
          value: row.$Value,
          Average_Sale:convertCurrency(returnAvg(row?.stus_sold, row?.$Value - row?.direct_expense),currencyRate
          ),
          CustomerId: row.id,
          Projects: row.projects,
          stus_expended: convertNumber(row.stus_expended, 2),
          stuExpended: row.stus_expended,
          Earned_rev:convertCurrency(row.revenue_value,currencyRate),
         
          earnedRev: row.revenue_value,
          Average_Earned:convertCurrency(returnAvg(
            row?.stus_expended,
            row?.revenue_value - row?.direct_expense
          ),currencyRate),
          Gained_base: checkNumber(row.gained_base, 2),
          Dropped_base: checkNumber(row.dropped_base, 2),
          gainedBase: row.gained_base,
          droppedBase: row.dropped_base,
          Future_earn:convertCurrency(futureSub(
            row?.$Value,
            row?.revenue_value,
            row?.gained_base,
            row?.dropped_base
          ),currencyRate)
          
        })
      )
    );
    setFilterData(tableData)
    
    
  }, [currencyRate]);
  const returnAvg = (stu: any, value: any) => {
    if (!stu || !value) {
      return 0;
    } else {
      return Number((value / stu).toFixed(2));
    }
  };
  const futureSub = (sol: any, ear: any, gain: any, dropped: any) => {
    sol = sol ? sol : 0;
    ear = ear ? ear : 0;
    gain = gain ? gain : 0;
    dropped = dropped ? dropped : 0;
    return Number((sol - ear + gain - dropped).toFixed(2));
  };
  const customerHeader = [
    { label: "CUSTOMER NAME", key: "Customer_name" },
    { label: "#PROJECTS", key: "Projects" },
    { label: "STU's SOLD", key: "Stu_Sold" },
    { label: "SOLD VALUE", key: "Value" },
    { label: "AVG SOLD/STU", key: "Average_Sale" },
    { label: "STU's Expended", key: "stus_expended" },
    { label: "Earned", key: "Earned_rev" },
    { label: "AVG EARNED/STU", key: "Average_Earned" },
    { label: "FUTURE EARNING", key: "Future_earn" },
  ];
  const getData = async (custIds?: number[]) => {
    let totalStuSold = 0;
    let totalRev = 0;
    let totalDirectExpense = 0;
    let totalAvgStu = 0;
    let totalExpStu = 0;
    let totalEar = 0;
    let totalAvgEar = 0;
    let totalFutu = 0;
    await AxiosConfig.get("customers", {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        custIds: custIds,
      },
    }).then((res) => {
      let customers = res.data.data.customers;
      if (customers.length >= 0) {
        setIsLoading(false);
      }
      customers = customers.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase()?.trim();
        let b1 = b.name.toLowerCase()?.trim();
      
        if (a1 < b1) {
          return -1;
        } else if (a1 > b1) {
          return 1;
        } else {
          return 0;
        }
      });
      
      setCustomers(
        customers.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
      setCustomersData(res.data.data.customers)
      setData(
        res.data.data.customers.map(
          (row: {
            name: any;
            id: any;
            stus_sold: any;
            $Value: any;
            projects: any;
            revenue_value: any;
            stus_expended: any;
            gained_base: any;
            dropped_base: any;
            droppedBase: any;
            gainedBase: any;
            Gained_base: any;
            Dropped_base: any;
            stuSold: any;
            direct_expense: any;
          }) => ({
            Customer_name: row.name,
            direct_expense: convertCurrency(
              
              row.direct_expense,currencyRate
            ),
            Stu_Sold: convertNumber(row.stus_sold, 2),
            stuSold: row.stus_sold,
            Value:convertCurrency(row.$Value,currencyRate),
            value_in_base: formatCurrencyValue(
              currencySymb?.currency_code,
              row.$Value
            ),
            value: row.$Value,
            Average_Sale:convertCurrency(returnAvg(row?.stus_sold, row?.$Value - row?.direct_expense),currencyRate
            ),
            CustomerId: row.id,
            Projects: row.projects,
            stus_expended: convertNumber(row.stus_expended, 2),
            stuExpended: row.stus_expended,
            Earned_rev:convertCurrency(row.revenue_value,currencyRate),
           
            earnedRev: row.revenue_value,
            Average_Earned:convertCurrency(returnAvg(
              row?.stus_expended,
              row?.revenue_value - row?.direct_expense
            ),currencyRate),
            Gained_base: checkNumber(row.gained_base, 2),
            Dropped_base: checkNumber(row.dropped_base, 2),
            gainedBase: row.gained_base,
            droppedBase: row.dropped_base,
            Future_earn:convertCurrency(futureSub(
              row?.$Value,
              row?.revenue_value,
              row?.gained_base,
              row?.dropped_base
            ),currencyRate)
            
          })
        )
      );
      res.data.data.customers.map((res) => {
        res.gained_base = res.gained_base ? res.gained_base : 0;
        res.dropped_base = res.dropped_base ? res.dropped_base : 0;
        totalStuSold = totalStuSold + res.stus_sold;
        totalRev = totalRev + res.$Value;
        totalDirectExpense = totalDirectExpense + res.direct_expense;
        totalAvgStu = totalAvgStu + returnAvg(res.stus_sold, res.$Value);
        totalExpStu = totalExpStu + res.stus_expended;
        totalEar = totalEar + res.revenue_value;
        totalAvgEar =
          totalAvgEar + returnAvg(res.stus_expended, res.revenue_value);
        totalFutu =
          totalFutu +
          futureSub(
            res.$Value,
            res.revenue_value,
            res.gained_base,
            res.dropped_base
          );
      });
      setTotalStuSold(totalStuSold);
      setTotalRev(totalRev);
      // setTotalDirectEexpense(totalDirectExpense);
      // setTotalAvgStu(totalAvgStu);
      setTotalExpStu(totalExpStu);
      setTotalEar(totalEar);
      // setTotalAvgEar(totalAvgEar);
      setTotalFutu(totalFutu);
      const tableData = res.data.data.customers.map(
        (row: {
          name: any;
          id: any;
          stus_sold: any;
          $Value: any;
          projects: any;
          direct_expense: any;
          revenue_value: any;
          stus_expended: any;
          gained_base: any;
          dropped_base: any;
        }) => ({
          Customer_name: row.name,
          Stu_Sold: convertNumber(row.stus_sold, 2),
          stuSold: row.stus_sold,
          direct_expense: row.direct_expense,
          Value: convertCurrency(row?.$Value,currencyRate),
          value: row.$Value,
          avgSale: returnAvg(row.stus_sold, row.$Value),
          Average_Sale:convertCurrency(returnAvg(row?.stus_sold, row?.$Value - row?.direct_expense),currencyRate),
          CustomerId: row.id,
          Projects: row.projects,
          stus_expended: convertNumber(row.stus_expended, 2),
          stuExpended: row.stus_expended,
          Earned_rev:convertCurrency( row?.revenue_value,currencyRate),
          earnedRev: row.revenue_value,
          avgEarned_filter: returnAvg(row.stus_expended, row.revenue_value),
          Average_Earned:convertCurrency( returnAvg(
            row?.stus_expended,
            row?.revenue_value - row?.direct_expense
          ),currencyRate),
          gainedBase: row.gained_base,
          droppedBase: row.dropped_base,
          futuEarned_filter: futureSub(
            row.$Value,
            row.revenue_value,
            row.gained_base,
            row.dropped_base
          ),
          Future_earn:convertCurrency(  futureSub(
            row?.$Value,
            row?.revenue_value,
            row?.gained_base,
            row?.dropped_base
          ),currencyRate)
        })
      );
      setFilterData(tableData);
      setFilterTotalCount(tableData?.length);
    });
  };
  const customerCategoryData = useSelector(
    (store: any) => store.categories.customerTypes
  );

  const [customersCategory, setCustomersCategory] = useState([]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      if (customerCategoryData) {
        setCustomersCategory(customerCategoryData);
      }
    }
  }, [customerCategoryData, currencySymb?.currency_code]);

  const tempCustomersCategoryData = customersCategory.map(function (customer: {
    name: any;
    id: any;
  }) {
    return {
      value: customer.id,
      label: customer.name,
    };
  });
  const categoryOptions = tempCustomersCategoryData?.sort((a, b) => a.label.localeCompare(b.label));

  const [editvisible, setEditVisible] = useState(false);

  const [custDetails, setCustDetails] = useState([]);
  const [custId, setCustId] = useState();

  const editvisibleHandler = (id: any) => {
    setEditVisible(true);
    setCustId(id);
    AxiosConfig.get("customers/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setCustDetails(res.data.data.customer);
    });
  };

  const editcloseHandler = () => {
    setEditVisible(false);
  };

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  function parseCurrency(value) {
    
    if (typeof value === "number") {
      return value;
    }
   
    if (!value) {
      return 0;
    }
   
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
  }
  
  const [visible, setVisible] = useState(false);

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };
  interface DataType {}
  const colums: ColumnsType<DataType> = [
    {
      title: "CUSTOMER NAME",
      fixed: "left",
      dataIndex: ["Customer_name", "Projects"],
      key: "name",
      render: (text: any, record: any) => (
        <div>
          <p className="stuSold_table">{record["Customer_name"]}</p>
          <p className="valueSold_table">#Projects : {record["Projects"]}</p>
        </div>
      ),
      filters: customers,
      sorter: (a: any, b: any) => a.Customer_name.trim().toLowerCase().localeCompare(b.Customer_name.trim().toLowerCase()),
      onFilter: (value: any, record: any) =>
        record.Customer_name.includes(value),
    },
    {
      title: "SOLD",
      children: [
        {
          title: "STU's SOLD",
          dataIndex: "Stu_Sold",
          key: "Stu_Sold",
          className: "text-end",
          sorter: (a: any, b: any) => a.stuSold - b.stuSold,
        },
        {
          title: "SOLD",
          dataIndex: "Value",
          key: "Value",
          className: "text-end",
          sorter: (a: any, b: any) => a.value - b.value,
        },
        {
          title: "AVG /STU",
          dataIndex: "Average_Sale",
          key: "Average_Sale",
          className: "text-end",
          sorter: (a: any, b: any) => a.avgSale - b.avgSale,
        },
      ],
    },
    {
      title: "EARNED/EXPENDED",
      children: [
        {
          title: "STU's Utilized ",
          dataIndex: "stus_expended",
          key: "stus_expended",
          className: "text-end",
          sorter: (a: any, b: any) => a.stuExpended - b.stuExpended,
        },
        {
          title: "Earned",
          dataIndex: "Earned_rev",
          key: "Earned_rev",
          className: "text-end",
          sorter: (a: any, b: any) => a.earnedRev - b.earnedRev,
        },
        {
          title: "AVG /STU",
          dataIndex: "Average_Earned",
          key: "Average_Earned",
          className: "text-end",
          sorter: (a: any, b: any) => a.avgEarned_filter - b.avgEarned_filter,
        },
      ],
    },
    {
      title: "FUTURE EARNING ",
      dataIndex: "Future_earn",
      key: "Future_earn",
      className: "text-end",
      sorter: (a: any, b: any) => a.futuEarned_filter - b.futuEarned_filter,
    },
    {
      title: "Edit",
      dataIndex: "CustomerId",
      key: "CustomerId",
      className: "text-end",
      render: (record: any) => (
        <>
          <div className="actionBtns" style={{ cursor: "pointer" }}>
            <span onClick={() => editvisibleHandler(record)}>
              <img src={edit} />
            </span>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="table-card">
      {editvisible && (
        <EditOverlay
          visible={editvisible}
          onClose={editcloseHandler}
          recordHandler={custId}
          onCancelButton={editcloseHandler}
          customerDetails={custDetails}
        ></EditOverlay>
      )}
      <Row>
        <Col md={8} xs={24}>
          <div className="searchForm">
            <form>
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by Customer Name"
              />
            </form>
          </div>
        </Col>
        <Col md={2} xs={24}>
          <label className="totalLabel">
            {customer.labelForTotal} {filterTotalCount}
          </label>
        </Col>
        <Col md={2} xs={24} lg={3} xl={3}>
          <label className="totalLabel"> {customer.labelForCategory} </label>
        </Col>
        <Col md={2} xs={24} className="locationDropdown">
          <ReactMultiSelectCheckboxes
            options={categoryOptions}
            placeholderButtonLabel="Select"
            onChange={(e) => {
              const custIds: number[] = e?.map((val: any) => val.value);
              getData(custIds);
            }}
          />
        </Col>
        <Col className="AddPoButton" md={8} xs={24}>
          <Button
            type="primary"
            onClick={visibleHandler}
            className="primary-btn"
          >
            {customer.buttonForAddCustomer}
          </Button>
          <CSVLink
            data={appliedFilters && Object.keys(appliedFilters).length > 0
              ? filterData.filter((item: any) => {
                  let matchFilters = true;
                  
                  if (appliedFilters.name && !appliedFilters.name.includes(item.Customer_name)) {
                    matchFilters = false;
                  }
                 
                  return matchFilters;
                })
              : filterData
          }
            target="_blank"
            filename={"customers.csv"}
            headers={customerHeader}
            className="exportTableButton"
            title="Export Table"
          >
            <ExportOutlined className="exportOultineIcon" />
          </CSVLink>
        </Col>
      </Row>

      <AddOverlay
        visible={visible}
        onClose={closeHandler}
        onCancelButton={closeHandler}
        customerDetails={custDetails}
      ></AddOverlay>

      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="tableOut noWordBreak">
          <Table
            dataSource={filterData}
            columns={colums}
            className="customerTable"
            bordered
            pagination={false}
            onChange={(pagination, filters, sorter, extra) => {
              const filteredData: any = extra.currentDataSource || [];
              let totalStuSold = 0;
              let totalRev = 0;
              // let totalDirectExpense = 0;
              let totalAvgStu = 0;
              let totalExpStu = 0;
              let totalEar = 0;
              // let totalAvgEar = 0;
              let totalFutu = 0;
            
              filteredData.forEach((res) => {
                res.gained_base = res.gained_base ? res.gained_base : 0;
                res.dropped_base = res.dropped_base ? res.dropped_base : 0;
                totalStuSold += parseFloat(res.stuSold) || 0;
                totalRev += parseCurrency(res.Value) || 0;
                // totalDirectExpense += parseFloat(res.direct_expense) || 0;
                totalAvgStu = (totalAvgStu + returnAvg(res.stuSold, res.value))
                totalExpStu += parseCurrency(res.stus_expended) || 0;
                totalEar += parseCurrency(res.earnedRev) || 0;
                // totalAvgEar += parseCurrency(res.Average_Earned) || 0;
                totalFutu += parseCurrency(res.Future_earn) || 0;
              });
              setTotalStuSold(totalStuSold);
              setTotalRev(totalRev);
              // setTotalDirectEexpense(totalDirectExpense);
              // setTotalAvgStu(totalAvgStu);
              setTotalExpStu(totalExpStu);
              setTotalEar(totalEar);
              // setTotalAvgEar(totalAvgEar);
              setTotalFutu(totalFutu);
              setAppliedFilters(filters);
              const filteredCount = filteredData.length;
              setFilterTotalCount(filteredCount);
            }}
            scroll={{ x: 300, y: 680 }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    {" "}
                    {customer.textForTotal}{" "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} className="totalNumber">
                    <Text>{convertNumber(getTotalStuSold, 2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} className="totalNumber">
                    <Text>
                      {convertCurrency(
                        getTotalRev,currencyRate
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="totalNumber">
                    <Text>
                      {convertCurrency(
                       getTotalRev/ getTotalStuSold,currencyRate
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="totalNumber">
                    <Text>{convertNumber(getTotalExpStu, 2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="totalNumber">
                    <Text>
                      {convertCurrency(
                        getTotalEar,currencyRate
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="totalNumber">
                    <Text>
                      {convertCurrency(
                        getTotalEar/getTotalExpStu ,currencyRate
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="totalNumber">
                    <Text>
                      {convertCurrency(
                        getTotalFutu,currencyRate
                      )}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          ></Table>
        </div>
      )}
    </div>
  );
};

export default Customers;
