import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, notification } from 'antd';
import type { ColumnType } from 'antd/es/table';
import "antd/dist/antd.css";
require("./Audits.scss");
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useHistory } from "react-router-dom";
import Loader from '../../../components/Loader/Loader';

const token = getToken();

interface AuditFormItem {
  id: number;
  review_type: string;
  totalProducts: number;
  interval:string;
  description: string;
  entity:string;
}

const Audits: React.FC = () => {
  const [auditData, setAuditData] = useState([]);
  let history = useHistory();
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const getAuditReview = async () => {
      try {
        const res = await AxiosConfig.get("reviewSettings", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAuditData(res.data.data.reviewData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching audit data:", error);
        setLoading(false);
      }
    };

    getAuditReview();
  }, []);

  const onClickRow = (record: any) => {
    history.push("/audits");
    localStorage.setItem("auditId", record);
  };

  const handleLinkClick = (event, record, text) => {
  if (!record.interval) {
    event.preventDefault();
    notification.info({
      message: "Feature Not Yet Developed",
      description: "This feature is currently under development.",
    });
    return;
  }

    if (record.entity === 'Employee') {
      event.preventDefault();
      notification.warning({
        message: 'Access Restricted',
        description: 'Page is In progress',
      });
    } else {
      onClickRow(record.id);
      localStorage.setItem('selectedAudit', text);
    }
  };

  const columns: ColumnType<AuditFormItem>[] = [
    {
      title: 'Audit Forms',
      dataIndex: 'review_type',
      key: 'review_type',
      render: (text,record) => (
        <span>
           <Link onClick={(event) => handleLinkClick(event, record, text)} to={{
            pathname: `/audits/${record.interval.toLowerCase()}/${record.id}`,
            state: { entity: record.entity },
          }}
            style={{ color: '#7788FB' }}  >
            {text}
          </Link>
        </span>
      ),
      align: 'center',
    },
    {
      title: 'Total Projects',
      dataIndex: 'project_count',
      key: 'project_count',
      render: (text) => (
        <span>
          {text} projects
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  return (
    <div>
      {loading ? ( 
        <Loader/> 
      ) : (
      <Table<AuditFormItem> dataSource={auditData} columns={columns} loading={loading} rowKey="id" bordered
       pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
      }}/>
)}
    </div>
  );
};

export default Audits;
